<template>
  <div>
    <card>
      <template #title>Ajustes de Parâmetros</template>
      <template #content>
        <div class="p-fluid formgrid grid items-center">
          <div
            v-for="parametro in parametros"
            :key="parametro.id"
            class="inline-block col-6 gap-2">
            <div>
              <label
                v-tooltip.left="parametro.descricao"
                :for="`parametro-${parametro.id}`"
                class="mb-3 block"
                >{{ parametro.nomeParametro }}</label
              >
            </div>
            <InputSwitch
              v-model="parametro.flag"
              :name="`parametro-${parametro.id}`"
              @change="() => alterarStatus(parametro)"
              @focus="toggleOverlay" />
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import AjusteParametrosService from '@/service/AjusteParametrosService.js'

export default {
  data() {
    return {
      parametros: [],
    }
  },

  async created() {
    this.service = new AjusteParametrosService(this.$http)
    await this.carregarStatusInicial()
    await this.service.getFlagsBloqueadas()
  },

  methods: {
    async alterarStatus(parametro) {
      try {
        await this.service.updateParametroFlag(parametro)
      } catch (error) {
        console.error('Erro ao alterar o status:', error)
        this.exibeToast('save-error', error.response.data.message)
      }
    },

    async carregarStatusInicial() {
      try {
        const data = await this.service.getParametroStatus()
        this.parametros = data
      } catch (error) {
        console.error('Erro ao carregar status inicial:', error)
        this.exibeToast('save-error', error.response.data.message)
      }
    },
  },
}
</script>
