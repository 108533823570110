export default class AjusteParametrosService {
  constructor(http) {
    this._http = http
  }

  async getParametroStatus() {
    const { data } = await this._http.get(`/api/parametros-flags`)
    return data
  }

  async updateParametroFlag(parametro) {
    const response = await this._http.put(`/api/parametros-flags`, parametro)
    return response.data
  }
  async getFlagsBloqueadas() {
    const { data } = await this._http.get(`/api/parametros-flags/bloqueadas`)
    return data
  }
}
